<template>
  <div id="page">
    <slot />

    <PageWatermark
      v-if="props.params.isWatermarkDisplayed"
      :data="{
        appearance: props.params.appearance,
        primaryColor: props.params.primaryColor,
        spacerTop: 'small',
        spacerBottom: 'small',
        alignment: 'center',
      }"
    />

    <PageStyleTag :content="customCss" />
    <PageStyleTag :content="customFontCss" />
  </div>
</template>

<script setup>
const props = defineProps({
  params: {
    type: Object,
    default: () => ({
      customCss: '',
      primaryColor: '#0000FF',
      appearance: 'light',
      isInitialized: false,
      isWatermarkDisplayed: true,
      font: null,
    }),
  },
  lang: {
    type: String,
    default: '',
  },
});

// Computed
const customCss = computed(() => prefixCustomCss(props.params?.customCss || '', '#page'));

const customFontCss = computed(() => {
  if (props.params.font) {
    return `
      @font-face {
        font-family: 'CustomPageFont';
        font-style: normal;
        font-weight: 400;
        src: url('${props.params.font.regular}') format('woff');
      }
      @font-face {
        font-family: 'CustomPageFont';
        font-style: normal;
        font-weight: 500;
        src: url('${props.params.font.medium}') format('woff');
      }
      @font-face {
        font-family: 'CustomPageFont';
        font-style: normal;
        font-weight: 600;
        src: url('${props.params.font.bold}') format('woff');
      }
      @font-face {
        font-family: 'CustomPageFont';
        font-style: normal;
        font-weight: 700;
        src: url('${props.params.font.bold}') format('woff');
      }`;
  }
  return '';
});

provide('lang', computed(() => `_${props.lang}`));
provide('eventTitle', computed(() => props.params?.general?.[`_${props.lang}`]?.eventTitle || ''));
</script>

<style>
#page {
  @apply font-page text-lg;

  .container {
    @apply mx-auto max-w-[75rem] w-[93%];

    &--narrow {
      @apply max-w-[52.5rem];
    }
  }

  h1 {
    @apply text-page-6xl font-semibold;

    & + p {
      @apply mt-4;
    }
  }

  h2 {
    @apply text-page-5xl font-semibold leading-[1.2];

    & + p {
      @apply mt-4;
    }
  }

  h3 {
    @apply text-page-2xl font-semibold;

    & + p {
      @apply mt-4;
    }
  }

  p {
    @apply text-page-lg text-(fg-muted);

    /* & + p {
      @apply mt-4;
    } */
  }

  img {
    @apply w-full;
  }

  ul,
  ol {
    @apply pl-[1.3rem] text-(fg-muted) inline-block text-left;
  }

  ul {
    @apply list-disc;
  }

  ol {
    @apply list-decimal;
  }

  .page-button {
    @apply transition-all inline-block text-center rounded-lg py-2.5 px-4 bg-(bg-emphasis) text-(fg-onemphasis) hover:brightness-[85%] font-bold text-base hover:cursor-pointer;
  }

  a {
    text-decoration: underline;
  }

  .white {
    p,
    ul,
    ol {
      @apply md:text-(fg-onemphasis);
    }
  }
}
</style>
