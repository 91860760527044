<template>
  <div
    :style="section.styles.cssVariables.value"
    class="bg-(bg-default) flex-1 flex items-end"
  >
    <div
      class="container border-t border-(border-muted)"
      :class="[
        section.styles.textAlignment.value,
        section.styles.spacerTop.value,
        section.styles.spacerBottom.value,
      ]"
    >
      <a
        href="http://attendu.com"
        target="_blank"
        class="text-(fg-subtle) text-base no-underline"
      >
        {{ section.$t('xyz15') }}

        <span class="text-(fg-default)">
          {{ section.$t('xyz16') }}
        </span>
      </a>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  data: {
    type: Object,
    default: () => ({}),
  },
});
const section = usePageSection(props);
</script>
